<template>
  <section>
    <div>
      <b-card>
        <h4>
          Log of changes on order {{ getCurrentOrder.orderNo }}
          <hr />
        </h4>
        <b-alert v-if="errormsg" show variant="danger">{{ errormsg }}</b-alert>
        <b-list-group>
          <b-list-group-item
            v-for="history in getOrderHistory"
            :key="history.id"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="m-1">{{ history.description }}</h5>
              <small>{{
                new Date(history.created).toLocaleDateString() +
                  " " +
                  new Date(history.created).toLocaleTimeString()
              }}</small>
            </div>
          </b-list-group-item>
        </b-list-group>
        <b-card v-if="getAppSettings.debug">
          <code>
            <pre>
 {{ getOrderHistory }}
            </pre>
          </code>
        </b-card>
      </b-card>
      <b-card>
        <Back-Button class="mr-2"></Back-Button>
      </b-card>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import BackButton from "@/components/Buttons/BackButton";

export default {
  name: "History",
  components: {
    BackButton
  },
  data() {
    return {
      orderId: "",
      errormsg: null
    };
  },
  methods: {
    ...mapActions(["loadOrderHistories", "loadOrder", "loadStatuses"])
  },
  computed: {
    ...mapGetters(["getOrderHistory", "getCurrentOrder", "getAppSettings"])
  },
  mounted() {
    window.scrollTo(0, 0);
    this.orderId = this.$route.params.id || null;

    this.loadStatuses();
    if (this.orderId) {
      this.loadOrder(this.orderId)
        .then(r => {
          self.order = r.data;
          self.shippingDate = moment(r.data.shippingDate).format("YYYY-MM-DD");
          self.deliveryDate = moment(r.data.deliveryDate).format("YYYY-MM-DD");
          self.receivedDate = moment(r.data.receivedDate).format("YYYY-MM-DD");

          if (
            r.data.statusNo !== "10" &&
            r.data.statusNo !== "1" &&
            self.getUser.role !== "Admin"
          ) {
            self.$router.push("/order/view/" + this.orderId);
          }
          if (r.data.projectId) {
            self.setProject(r.data.projectId);
          }
          this.isLoading = false;
        })
        .catch(e => {
          self.errormsg = "Failed when loading order: " + e;
        });
      this.loadOrderHistories(this.orderId);
    }
  }
};
</script>
